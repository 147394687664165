import React, { useState, useEffect } from 'react';
import imageSchool from '/images/cct-3.png';
import students from '/images/vec-1.png';
import FilterKRT from './FilterKRT'
import { Table, } from 'antd';


const MAP_SUNGKUD = {
  'obec': 'สพฐ.',
  'dla': 'อปท.',
  'bpp': 'ตชด.',
  'opec': 'สช.',
  'onab': 'พศ.',
}
const SungkudView = (props) => {
  let { data, filter, setFilter } = props;

  // const [data, setData] = useState({
  //   school: 0,
  //   student_exjon: 0,
  //   dataTable: [],
  //   name: '',
  //   key_group: 'province_name',
  //   class_k: 0,
  //   class_p: 0,
  //   class_m: 0,
  //   class_h: 0,
  //   student_jon100persent: 0,
  //   school_jon100persent: 0,
  //   school_jon: 0,
  //   student_jon: 0,
  //   school_total: 0,
  //   countAllTunStudent: props.data?.countAllTunStudent ? props.data.countAllTunStudent : 0,
  //   countAllTunSchool: props.data?.countAllTunSchool ? props.data.countAllTunSchool : 0,
  //   sum_student: (
  //     props.data.student_exjon_25612 +
  //     props.data.student_exjon_25621 +
  //     props.data.student_exjon_25622 +
  //     props.data.student_exjon_25631 +
  //     props.data.student_exjon_25632 +
  //     props.data.student_exjon_25641 +
  //     props.data.student_exjon_25642
  //   )
  // });

  // useEffect(() => {
  //   let student_exjon = 0
  //   let school = 0
  //   let school_total = 0
  //   let key_group = 'province_name'
  //   let result = {}
  //   let name = ''
  //   let class_k = 0
  //   let class_p = 0
  //   let class_m = 0
  //   let class_h = 0
  //   let school_jon = 0
  //   let student_jon = 0
  //   let student_jon100persent = 0
  //   let school_jon100persent = 0
  //   let exjon = 0
  //   let dataTable = {
  //     'ขนาดเล็ก': { key: 'เล็ก', school: 0, student: 0, exjon: 0 },
  //     'ขนาดกลาง': { key: 'กลาง', school: 0, student: 0, exjon: 0 },
  //     'ขนาดใหญ่': { key: 'ใหญ่', school: 0, student: 0, exjon: 0 },
  //     'ขนาดใหญ่พิเศษ': { key: 'ใหญ่พิเศษ', school: 0, student: 0, exjon: 0 }
  //   }

  //   if (props.data[`data${filter.year}${filter.semester}`]) {
  //     let _data = props.data[`data${filter.year}${filter.semester}`]
  //     let school_total = 0
  //     // _data.length
  //     if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

  //     if (filter.view === 'country') {
  //       key_group = 'province_name'
  //       _data = _data.map(x => ({ ...x, type: 'province_name' }))
  //     }

  //     if (filter.view === 'province') {
  //       key_group = 'city_name'
  //       name = 'จังหวัด' + filter.province_name
  //       _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
  //     }


  //     if (filter.view === 'city') {
  //       key_group = 'tumbon_name'
  //       name = 'อำเภอ' + filter.city_name
  //       _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
  //         .map(x => ({ ...x, type: 'tumbon_name' }))
  //     }

  //     // list_school
  //     _data.forEach(element => {
  //       school += 1
  //       school_total += 1
  //       student_exjon += element[`total_exjon`]

  //       if (!result[element[key_group]]) {
  //         result[element[key_group]] = {
  //           key: element[key_group],
  //           school: 0,
  //           student: 0,
  //           student_jon: 0,
  //           student_exjon: 0,
  //           sum_jon_exjon: 0,

  //           class_k: 0,
  //           class_p: 0,
  //           class_m: 0,
  //           class_h: 0,
  //           school_jon100: 0,
  //         }
  //       }
  //       result[element[key_group]].school += 1
  //       result[element[key_group]].student += element[`total_student`]
  //       result[element[key_group]].student_jon += element[`total_jon`]
  //       result[element[key_group]].student_exjon += element[`total_exjon`]
  //       result[element[key_group]].sum_jon_exjon += (element[`total_jon`] + element[`total_exjon`])

  //       exjon += element[`total_exjon`]

  //       if (element.k_exjon > 0) class_k += element.k_exjon
  //       if (element.p_exjon > 0) class_p += element.p_exjon
  //       if (element.m_exjon > 0) class_m += element.m_exjon
  //       if (element.h_exjon > 0) class_h += element.h_exjon
  //       // school_jon = (class_k + class_p + class_m + class_h)
  //       if (element.total_exjon > 0) {
  //         school_jon += 1
  //       }
  //       student_jon += element.total_exjon
  //       if (dataTable[element.school_size]) {
  //         dataTable[element.school_size].school += 1
  //         dataTable[element.school_size].student += element.total_student
  //         dataTable[element.school_size].exjon += element.total_exjon
  //       }
  //       if (element.school_jon100 === 1) {
  //         school_jon100persent += 1
  //         student_jon100persent += element.total_exjon
  //         result[element[key_group]].school_jon100persent += 1
  //         result[element[key_group]].student_jon100persent += element.total_exjon
  //       }

  //     })
  //     setData({
  //       ...data,
  //       ...{
  //         school_total,
  //         school: school,
  //         student_exjon: student_exjon,
  //         dataTable: [...Object.values(dataTable)],
  //         key_group,
  //         class_k,
  //         class_p,
  //         class_m,
  //         class_h,
  //         school_jon,
  //         student_jon,
  //         school_jon100persent,
  //         student_jon100persent,
  //       }
  //     })

  //   }

  // }, [props.data, filter])

  return (
    <div className='flex flex-col'>
      <p className='text-center text-base text-sm xl:text-md font-baijam'>
        โครงการทุนเสมอภาคได้เริ่มช่วยเหลือนักเรียนตั้งแต่ภาคเรียนที่ 2561 จนถึง  2566  <br />
        โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ โดยให้  <br />
        ความช่วยเหลือกลุ่มเป้าหมายจำนวน <span className='text-[#038967] text-xl'> {data.countAllTunStudent.toLocaleString("en-US")}</span> ทุน ครอบคลุมโรงเรียน <span className='text-[#038967] text-xl'> {data.countAllTunSchool.toLocaleString("en-US")}</span> โรงเรียน ใน 6 สังกัด
      </p>
      <div className='col-span-10 flex-col'>
        <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full'>
          < FilterKRT
            data={filter}
            onChange={(data) => {
              setFilter({ ...data })
            }}
          />
        </div>
      </div>


      <div className='grid grid-cols-10 gap-1 mt-4'>

        {/* <div className='col-span-12 lg:col-span-5 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='flex p-2  hidden xl:flex' >
              <img className="self-center w-[64px]" src={imageSchool} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>จำนวนโรงเรียนทั้งหมด</span>
              <span className='text-2xl font-bold text-[#3b69df] text-center'>{data.school.toLocaleString("en-US")} โรงเรียน</span>
            </div>
          </div>
        </div> */}

        <div className='col-span-12 flex-col'>
          <div className='relativef flex flex-row  justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='grid grid-cols-10 gap-1'>


              <div className='col-span-12 lg:col-span-5 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around'>
                  <div className='p-2  hidden xl:flex' >
                    <img className="self-center w-[64px]" src={imageSchool} alt="image for banner" />
                  </div>
                  <div className='flex flex-col p-2'>
                    <span className='text-lg lx:text-xl font-bold  text-center mt-3'>จำนวนโรงเรียนทั้งหมด</span>
                    <span className='text-2xl font-bold text-[#3b69df] text-center'>{data.school_total.toLocaleString("en-US")} โรงเรียน</span>
                  </div>
                </div>
              </div>


              <div className='col-span-12 lg:col-span-5 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden flex flex-row justify-around'>
                  <div className='p-2  hidden xl:flex' >
                    <img className="self-center w-[64px]" src={students} alt="image for banner" />
                  </div>
                  <div className='flex flex-col p-2'>
                    <span className='text-lg lx:text-xl font-bold  text-center'>จำนวนนักเรียนที่ได้รับทุน</span>
                    <span className='text-2xl font-bold text-[#048967] text-center'>{data.student_jon.toLocaleString("en-US")} คน</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col mt-2'>
          <div className='relativef flex flex-col justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='text-xl xl:text-2xl font-bold text-[#333] text-center'>จำนวนนักเรียนที่ได้รับทุนแบ่งตามช่วงชั้นปี {filter.year}เทอม{filter.semester}</div>
            <div className='grid grid-cols-12 gap-1 md:divide-x mt-2'>
              <div className='col-span-12 md:col-span-3 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around'>
                  <div className='flex flex-col p-2'>
                    <span className='text-lg lx:text-xl font-bold  text-center'>อนุบาล</span>
                    <span className='text-2xl font-bold text-[#048967] text-center'>{data.class_k.toLocaleString("en-US")} คน</span>
                  </div>
                </div>
              </div>

              <div className='col-span-12 md:col-span-3 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around'>
                  <div className='flex flex-col p-2'>
                    <span className='text-lg lx:text-xl font-bold  text-center'>ประถมศึกษา</span>
                    <span className='text-2xl font-bold text-[#048967] text-center'>{data.class_p.toLocaleString("en-US")} คน</span>
                  </div>
                </div>
              </div>

              <div className='col-span-12 md:col-span-3 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around'>
                  <div className='flex flex-col p-2'>
                    <span className='text-md lx:text-lg font-bold  text-center'>มัธยมศึกษาตอนต้น</span>
                    <span className='text-2xl font-bold text-[#048967] text-center'>{data.class_m.toLocaleString("en-US")} คน</span>
                  </div>
                </div>
              </div>

              <div className='col-span-12 md:col-span-3 flex-col'>
                <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around'>
                  <div className='flex flex-col p-2'>
                    <span className='text-md lx:text-lg font-bold  text-center'>มัธยมศึกษาตอนปลาย</span>
                    <span className='text-2xl font-bold text-[#048967] text-center'>{data.class_h.toLocaleString("en-US")} คน</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className='col-span-10 md:col-span-5 flex-col'>
          <div className='text-xl font-bold text-center mt-3 text-[#333]'>จำนวนนักเรียนที่ได้รับทุน<br />แบ่งตามขนาดโรงเรียน</div>
          <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl h-[280px]">
            <div class="grid grid-cols-3 divide-x border-b-[1px] border-b-solid border-b-[#a3a3a3] p-[10px]">
              <div className=" text-black text-center text-md">ขนาดโรงเรียน</div>
              <div className=" text-black text-center text-md">จำนวนโรงเรียน (รร.)</div>
              <div className=" text-black text-center text-md">จำนวนนักเรียน (คน)</div>
            </div>
            {
              data.dataTable.map(item => (
                < div className="flex justify-between items-center p-[10px]" >
                  <div className="flex-1 text-black text-md">{item.key}</div>
                  <div className="flex-1 text-[#3b69df] text-right text-md px-1 font-bold">{item.school.toLocaleString("en-US")}</div>
                  <div className="flex-1 text-[#169373] text-right text-md px-1 font-bold">{item.exjon.toLocaleString("en-US")}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div className='col-span-10 md:col-span-5 flex-col'>
          <div className='text-xl font-bold text-center mt-3 text-[#333]'>จำนวนโรงเรียนที่มี<br />นักเรียนยากจน 100%</div>
          <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl h-[280px] justify-around">
            <div className='mt-8'>
              <div className='text-xl font-bold text-[#333] text-center'>จำนวนโรงเรียน</div>
              <div className='text-4xl font-bold text-[#3b69df] text-center'>{data.school_jon100persent.toLocaleString("en-US")} โรงเรียน</div>
            </div>
            <div className='w-full h-[2px] bg-gray-200 mt-3'></div>
            <div>
              <div className='text-xl font-bold text-[#333] text-center mt-3'>จำนวนนักเรียนทุนเสมอภาค</div>
              <div className='text-4xl font-bold text-[#169373] text-center'>{data.student_jon100persent.toLocaleString("en-US")} คน</div>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
}

export default SungkudView
