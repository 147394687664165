import React, { useState, useEffect } from 'react';
import imageSchool from '/images/cct-3.png';
import krtSudentCapital from '/images/cct-2.png';
import students_black from '/images/student-black.png';
import students_red from '/images/student-red.png';
import students_blue from '/images/student-blue.png';
import logo1 from '/images/home12.png';
import logo2 from '/images/home8.png';
import logo3 from '/images/home10.png';
import logo4 from '/images/home11.png';
import logo5 from '/images/home13.png';
import logo6 from '/images/logo-bkk.png';
import FilterKRT from './FilterKRT'

const Overview = (props) => {
  let { data, filter, setFilter } = props;
  return (
    <div className='flex flex-col'>
      <p className='text-center text-base text-sm xl:text-md font-baijam'>
        โครงการทุนเสมอภาคได้เริ่มช่วยเหลือนักเรียนตั้งแต่ภาคเรียนที่ 2561 จนถึง  2566 <br />
        โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ <br />
        โดยมีนักเรียนได้รับทุนเสมอภาคจำนวน <span className='text-[#038967] text-2xl'> {data.countAllTunStudent.toLocaleString("en-US")}</span> คน
        ครอบคลุมโรงเรียน <span className='text-[#038967] text-2xl'> {data.countAllTunSchool.toLocaleString("en-US")}</span> โรงเรียน ใน 6 สังกัด
      </p>


      <div className='grid grid-cols-12 mt-4 gap-2 w-full'>

        <div className='col-span-12 flex-col'>
          <div className='text-xl font-bold'>จำนวนการช่วยเหลือทั้งหมดตั้งแต่ภาคเรียนที่ 2/2561 จนถึง  2/2566</div>
          <div className='grid grid-cols-12 mt-2 gap-2 w-full'>
            <div className='col-span-12 lg:col-span-6 flex-col'>
              <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 p-2 bg-white '>
                <div className='flex p-2  hidden xl:flex' >
                  <img className="self-center w-[72px]" src={krtSudentCapital} alt="image for banner" />
                </div>
                <div className='flex flex-col p-2'>
                  <span className='text-xl lx:text-xl font-bold  text-center mt-2'>จำนวนนักเรียนที่ได้รับทุน</span>
                  <span className='text-3xl font-bold text-[#3b69df] text-center'>{data.countAllTunStudent.toLocaleString("en-US")} คน</span>
                </div>
              </div>
            </div>

            <div className='col-span-12 lg:col-span-6 flex-col'>
              <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 p-2 bg-white '>
                <div className='flex p-2  hidden xl:flex' >
                  <img className="self-center w-[72px]" src={imageSchool} alt="image for banner" />
                </div>
                <div className='flex flex-col p-2'>
                  <span className='text-xl lx:text-xl font-bold  text-center mt-2'>จำนวนโรงเรียนที่ได้ทุน</span>
                  <span className='text-3xl font-bold text-[#3b69df] text-center'>{data.countAllTunSchool.toLocaleString("en-US")} รร.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col'>
          {/* <div className='text-xl font-bold mt-4'>จำนวนการช่วยเหลือภาคเรียนที่ 2565 แบ่งตามสังกัด</div> */}
          <div className='text-xl font-bold mt-4'>จำนวนการช่วยเหลือภาคเรียนที่ 2/2566 แบ่งตามสังกัด</div>

          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 bg-white'>
            <div className='flex p-2 mx-2 flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo1} alt="image for banner" />
              <div className='text-lg'>สพฐ.</div>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['สพฐ.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['สพฐ.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 bg-white'>
            <div className='flex  p-2 mx-2 flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo2} alt="image for banner" />
              <div className='text-lg'>ตชด.</div>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['ตชด.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['ตชด.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>


        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 bg-white'>
            <div className='flex  p-2 mx-2  flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo3} alt="image for banner" />
              <div className='text-lg'>อปท.</div>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['อปท.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['อปท.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>


        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200  bg-white'>
            <div className='flex  p-2 mx-2 flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo4} alt="image for banner" />
              <div className='text-lg'>สช.</div>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['สช.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['สช.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 bg-white'>

            <div className='flex p-2 mx-2 flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo5} alt="image for banner" />
              <div className='text-lg'>พศ.</div>
            </div>

            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['พศ.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['พศ.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden flex flex-col md:flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 bg-white'>

            <div className='flex p-2 mx-2 flex-col items-center justify-items-center' >
              <img className="self-center w-[72px]" src={logo6} alt="image for banner" />
              <div className='text-lg'>กทม.</div>
            </div>

            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-0 md:mt-7'>จำนวนนักเรียนที่ได้รับทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['กทม.'].sum_exjon.toLocaleString("en-US")} คน</p>
            </div>
            <div className='flex flex-1 flex-col'>
              <span className='text-xl font-bold text-center mb-0 mt-2 md:mt-7'>จำนวนโรงเรียนที่ได้ทุน</span>
              <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{data.dataTableSungkud['กทม.'].school_jon_sum.toLocaleString("en-US")} รร.</p>
            </div>
          </div>
        </div>

        <div className='col-span-12 flex-col'>
          <div className='text-lg font-bold mb-4'>*หมายเหตุ : เด็กนักเรียนทุนเสมอภาคที่ยุติการรับทุนหรือจบการศึกษาจำนวน
            <span className='text-3xl text-[#3b69df] mx-2'>{(data.countAllTunStudent - data.student_exjon_25652).toLocaleString("en-US")}</span> คน
          </div>
        </div>


        <div className='col-span-12 flex-col'>
          <div className='relative w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
            {/* <div className='relative w-full rounded-lg overflow-hidden  flex flex-row justify-around  shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full'> */}

            <div className='flex flex-col p-4'>
              <span className='text-xl font-bold text-center'>จำนวนนักเรียนที่ได้รับทุนเสมอภาคแบ่งตามภาคเรียน</span>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2566</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#038967] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25662 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25662.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 1/2566</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25661 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25661.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2565</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25652 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25652.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 1/2565</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25651 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25651.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2564</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25642 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25642.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 1/2564</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25641 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25641.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2563</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25632 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25632.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 1/2563</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25631 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25631.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2562</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25622 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25622.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 1/2562</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25621 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25621.toLocaleString("en-US")} คน
                </h3>
              </div>

              <span className='text-xl font-bold text-[#333]'>ภาคเรียน 2/2561</span>
              <div className="w-full rounded-full h-2.5 my-2 flex flex-row items-center">
                <div className="bg-[#A6D5C9] h-2.5 rounded-full" style={{ width: `${(data.student_exjon_25612 / data.student_max_exjon) * 60}%` }}></div>
                <h3 className="ml-2">
                  {data.student_exjon_25612.toLocaleString("en-US")} คน
                </h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
}

export default Overview
