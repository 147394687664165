import React, { useState, useEffect } from 'react';
import imageSchool from '/images/cct-3.png';
import students from '/images/vec-1.png';

import FilterKRT from './FilterKRT'
import { Table, Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
const map_key_group = {
  'province_name': 'จังหวัด',
  'city_name': 'อำเภอ',
  'tumbon_name': 'ตำบล',
}

const MapAreaView = (props) => {
  let { data, filter, setFilter } = props;

  // const [data, setData] = useState({
  //   school: 0,
  //   student_exjon: 0,
  //   dataTable: [],
  //   name: '',
  //   key_group: 'province_name',
  //   countAllTunStudent: props.data?.countAllTunStudent ? props.data.countAllTunStudent : 0,
  //   countAllTunSchool: props.data?.countAllTunSchool ? props.data.countAllTunSchool : 0,
  //   sum_student: (
  //     props.data.student_exjon_25612 +
  //     props.data.student_exjon_25621 +
  //     props.data.student_exjon_25622 +
  //     props.data.student_exjon_25631 +
  //     props.data.student_exjon_25632 +
  //     props.data.student_exjon_25641 +
  //     props.data.student_exjon_25642
  //   )
  // });

  // useEffect(() => {
  //   let student_exjon = 0
  //   let school = 0
  //   let schoolsize = 0
  //   let key_group = 'province_name'
  //   let result = {}
  //   let name = ''

  //   if (props.data[`data${filter.year}${filter.semester}`]) {
  //     let _data = props.data[`data${filter.year}${filter.semester}`]
  //     let schoolsize = 0
  //     // _data.length

  //     if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

  //     if (filter.view === 'country') {
  //       key_group = 'province_name'
  //       _data = _data.map(x => ({ ...x, type: 'province_name' }))
  //     }

  //     if (filter.view === 'province') {
  //       key_group = 'city_name'
  //       name = 'จังหวัด' + filter.province_name
  //       _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
  //     }


  //     if (filter.view === 'city') {
  //       key_group = 'tumbon_name'
  //       name = 'อำเภอ' + filter.city_name
  //       _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
  //         .map(x => ({ ...x, type: 'tumbon_name' }))
  //     }

  //     _data.forEach(element => {
  //       school += 1
  //       student_exjon += element[`total_exjon`]
  //       if (!result[element[key_group]]) {
  //         result[element[key_group]] = {
  //           key: element[key_group],
  //           school: 1,
  //           student: element[`total_student`],
  //           student_target: element[`total_target`],
  //           student_jon: element[`total_jon`],
  //           student_exjon: element[`total_exjon`],
  //           sum_jon_exjon: (element[`total_jon`] + element[`total_exjon`]),
  //         }

  //       } else {
  //         result[element[key_group]].school += 1
  //         result[element[key_group]].student += element[`total_student`]
  //         result[element[key_group]].student_target += element[`total_target`]
  //         result[element[key_group]].student_jon += element[`total_jon`]
  //         result[element[key_group]].student_exjon += element[`total_exjon`]
  //         result[element[key_group]].sum_jon_exjon += (element[`total_jon`] + element[`total_exjon`])
  //       }
  //     })


  //     setData({
  //       ...data,
  //       ...{
  //         // school: school,
  //         school: school,
  //         student_exjon: student_exjon,
  //         dataTable: [...Object.values(result)],
  //         key_group,
  //         sum_student: (
  //           props.data.student_exjon_25612 +
  //           props.data.student_exjon_25621 +
  //           props.data.student_exjon_25622 +
  //           props.data.student_exjon_25631 +
  //           props.data.student_exjon_25632 +
  //           props.data.student_exjon_25641 +
  //           props.data.student_exjon_25642
  //         )
  //       }
  //     })

  //   }

  // }, [props.data, filter])

  // if (!props.data[`data${filter.year}${filter.semester}`]) {
  //   return null
  // }

  return (
    <div>
      <p className='text-center text-base text-sm xl:text-md font-baijam'>
        โครงการทุนเสมอภาคได้เริ่มช่วยเหลือนักเรียนตั้งแต่ภาคเรียนที่ 2561 จนถึง 2566 <br />
        โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ <br />
        โดยมีนักเรียนได้รับทุนเสมอภาคจำนวน <span className='text-[#038967] text-2xl'> {data.countAllTunStudent.toLocaleString("en-US")}</span> คน
        ครอบคลุมโรงเรียน <span className='text-[#038967] text-2xl'> {data.countAllTunSchool.toLocaleString("en-US")}</span> โรงเรียน ใน 6 สังกัด
      </p>

      <div className='grid grid-cols-10 gap-1'>

        <div className='col-span-10 flex-col'>
          <div className='relativef flex flex-row  justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full'>
            < FilterKRT
              data={filter}
              onChange={(data) => {
                setFilter({ ...data })
              }}
            />
          </div>
        </div>

        <div className='col-span-12 lg:col-span-5 flex-col'>
          <div className='relativef flex flex-row  justify-center rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='p-2 hidden xl:flex' >
              <img className="self-center w-[64px]" src={imageSchool} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>จำนวนโรงเรียนทั้งหมด</span>
              <span className='text-2xl font-bold text-[#3b69df] text-center'>{data.school.toLocaleString("en-US")} โรงเรียน</span>
            </div>
          </div>
        </div>


        <div className='col-span-12 lg:col-span-5 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white mt-2'>
            <div className='p-2  hidden xl:flex' >
              <img className="self-center w-[64px] hidden xl:flex" src={students} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>จำนวนนักเรียนที่ได้รับทุน</span>
              <span className='text-2xl font-bold text-[#048967] text-center'>{data.student_exjon.toLocaleString("en-US")} คน</span>
            </div>
          </div>
        </div>

        <div className='col-span-12 lg:col-span-10 flex-col'>
          <div className='relativef flex flex-col justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white mt-2'>
            <div className='flex flex-row justify-between'>
              <div className='text-xl font-bold text-center text-[#333] m-1 mt-2'>จำนวนนักเรียนที่ได้รับทุนเสมอภาคแบ่งตามจังหวัดปี {filter.year}เทอม{filter.semester}</div>
              <div className='hidden md:flex'>
                <Button type="primary" shape="round" icon={<FileExcelOutlined />} size={'middle'}
                  style={{ margin: '10px' }}
                  onClick={() => {
                    props.exportXLSXbyProvince()
                  }}
                >
                  โหลด Excel
                </Button>
              </div>
            </div>
            <Table
              size="small"
              bordered
              style={{ width: '100%' }}
              scroll={{ x: 1200, y: 500 }}
              columns={[
                {
                  title: map_key_group[data.key_group],
                  dataIndex: 'key',
                  key: 'key',
                  align: 'center',
                  // defaultSortOrder: 'ascend',
                  //           dataIndex: 'key',
                  //           key: 'key',
                  width: 150,
                  fixed: 'left',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "left" }
                      },
                      children: (data.key_group === 'tumbon_name' ? <p>{text}</p> : <a onClick={() => {
                        if (data.key_group === 'province_name') {
                          setFilter({
                            ...filter,
                            ...{
                              province_name: text,
                              view: 'province',
                              city_name: null,
                            }
                          })
                        } if (data.key_group === 'city_name') {
                          setFilter({
                            ...filter,
                            ...{
                              view: 'city',
                              city_name: text,
                            }
                          })

                        }

                      }}>{text}</a>)
                    };
                  },
                  sorter: (a, b) => {
                    if (a.key > b.key) {
                      return -1;
                    }
                    if (b.key > a.key) {
                      return 1;
                    }
                    return 0;
                  }
                  //  (a, b) => a.key - b.key,
                },
                {
                  title: 'จำนวนโรงเรียน',
                  dataIndex: 'school',
                  key: 'school',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school - b.school,
                },
                {
                  title: 'จน.นร. ทั้งหมด',
                  dataIndex: 'student',
                  key: 'student',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student - b.student,
                },
                {
                  title: 'จน.นร. กลุ่มเป้าหมาย',
                  dataIndex: 'student_target',
                  key: 'student_target',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student_target - b.student_target,
                },
                {
                  title: 'นร.ทุนปัจจัยพื้นฐาน(คน)',
                  dataIndex: 'student_jon',
                  key: 'student_jon',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student_jon - b.student_jon,
                },
                {
                  title: 'นร.ทุนปัจจัยพื้นฐาน(%)',
                  dataIndex: 'student',
                  key: 'student',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{(record.student_jon / record.student_target * 100).toFixed(2)}%</div>
                    };
                  },
                  sorter: (a, b) => (a.student_jon / a.student_target) - (b.student_jon / b.student_target),
                },

                {
                  title: 'นร.ทุนเสมอภาค(คน)',
                  dataIndex: 'student_exjon',
                  key: 'student_exjon',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student_exjon - b.student_exjon,
                },
                {
                  title: 'นร.ทุนเสมอภาค(%)',
                  dataIndex: 'student',
                  key: 'student',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{(record.student_exjon / record.student_target * 100).toFixed(2)}%</div>
                    };
                  },
                  sorter: (a, b) => (a.student_exjon / a.student_target) - (b.student_exjon / b.student_target),
                },
                // {
                //   title: 'นร.ยากจน+ยากจนพิเศษ',
                //   dataIndex: 'sum_jon_exjon',
                //   key: 'sum_jon_exjon',
                //   align: 'center',
                //   render(text, record) {
                //     return {
                //       props: {
                //         // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                //         style: { textAlign: "right" }
                //       },
                //       children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                //     };
                //   },
                //   sorter: (a, b) => a.sum_jon_exjon - b.sum_jon_exjon,
                // },
                // {
                //   title: 'นร.ยากจน+ยากจนพิเศษ(%)',
                //   dataIndex: 'student',
                //   key: 'student',
                //   align: 'center',
                //   render(text, record) {
                //     return {
                //       props: {
                //         style: { textAlign: "right" }
                //       },
                //       children: <div>{(record.sum_jon_exjon / record.student_target * 100).toFixed(2)}%</div>
                //     };
                //   },
                //   sorter: (a, b) => (a.sum_jon_exjon / a.student_target) - (b.sum_jon_exjon / b.student_target),
                // }
              ]}
              dataSource={data.dataTable}
            >

            </Table>
          </div>
        </div>

      </div>
    </div >
  )
}

export default MapAreaView
