import React, { useState, useEffect } from 'react';
import imageSchool from '/images/cct-3.png';
import students from '/images/vec-1.png';
import krtSudentCapital from '/images/cct-2.png';
import { Table, } from 'antd';
import FilterKRT from './FilterKRT'

const SungkudView = (props) => {

  let { data, filter, setFilter } = props;

  // const [data, setData] = useState({
  //   school: 0,
  //   student_exjon: 0,
  //   dataTable: [],
  //   dataTableSungkud: [],
  //   dataTableJon100: [],
  //   name: '',
  //   key_group: 'province_name',
  //   class_k: 0,
  //   class_p: 0,
  //   class_m: 0,
  //   class_h: 0,
  //   student_jon100persent: 0,
  //   student_exjon100persent: 0,
  //   school_jon100persent: 0,
  //   school_jon: 0,
  //   student_jon: 0,
  // });

  // useEffect(() => {
  //   let student_exjon = 0
  //   let school_total = 0
  //   let school = 0
  //   let key_group = 'province_name'
  //   let result = {}
  //   let name = ''
  //   let class_k = 0
  //   let class_p = 0
  //   let class_m = 0
  //   let class_h = 0
  //   let school_jon = 0
  //   let student_jon = 0
  //   let student_jon100persent = 0
  //   let student_exjon100persent = 0
  //   let school_jon100persent = 0
  //   let exjon = 0
  //   let dataTable = {
  //     'ตะวันออกเฉียงเหนือ': { key: 'ตะวันออกเฉียงเหนือ', school: 0, student: 0, jon: 0, exjon: 0 },
  //     'กลาง': { key: 'กลาง', school: 0, student: 0, jon: 0, exjon: 0 },
  //     'เหนือ': { key: 'เหนือ', school: 0, student: 0, jon: 0, exjon: 0 },
  //     'ใต้': { key: 'ใต้', school: 0, student: 0, jon: 0, exjon: 0 }
  //   }

  //   let dataTableSungkud = {
  //     'สพฐ.': { key: 'สพฐ.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'อปท.': { key: 'อปท.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'ตชด.': { key: 'ตชด.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'สช.': { key: 'สช.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'พศ.': { key: 'พศ.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //   }

  //   if (props.data[`data${filter.year}${filter.semester}`]) {
  //     let _data = props.data[`data${filter.year}${filter.semester}`]

  //     if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

  //     if (filter.view === 'country') {
  //       key_group = 'province_name'
  //       _data = _data.map(x => ({ ...x, type: 'province_name' }))
  //     }

  //     if (filter.view === 'province') {
  //       key_group = 'city_name'
  //       name = 'จังหวัด' + filter.province_name
  //       _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
  //     }


  //     if (filter.view === 'city') {
  //       key_group = 'tumbon_name'
  //       name = 'อำเภอ' + filter.city_name
  //       _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
  //         .map(x => ({ ...x, type: 'tumbon_name' }))
  //     }

  //     _data.forEach(element => {
  //       if (element.school_jon100 === 1) {
  //         school_jon100persent += 1
  //       }
  //     })

  //     _data.forEach(element => {
  //       school += 1
  //       student_exjon += element.total_exjon

  //       if (!result[element[key_group]]) {
  //         result[element[key_group]] = {
  //           key: element[key_group],
  //           school: 0,
  //           student: 0,
  //           student_jon: 0,
  //           student_exjon: 0,
  //           sum_jon_exjon: 0,

  //           class_k: 0,
  //           class_p: 0,
  //           class_m: 0,
  //           class_h: 0,
  //           school_jon100: 0,
  //           student_jon100persent: 0,
  //           student_exjon100persent: 0,
  //           school_jon100persent: 0,
  //         }
  //       }
  //       result[element[key_group]].school += 1
  //       result[element[key_group]].student += element[`total_student`]
  //       result[element[key_group]].student_jon += element[`total_jon`]
  //       result[element[key_group]].student_exjon += element[`total_exjon`]
  //       result[element[key_group]].sum_jon_exjon += (element[`total_jon`] + element[`total_exjon`])

  //       exjon += element[`total_exjon`]

  //       school_total += 1
  //       student_jon += element.total_exjon
  //       if (element.total_exjon > 0) {
  //         school_jon += 1
  //       }
  //       if (element.k_exjon > 0) class_k += element.k_exjon
  //       if (element.p_exjon > 0) class_p += element.p_exjon
  //       if (element.m_exjon > 0) class_m += element.m_exjon
  //       if (element.h_exjon > 0) class_h += element.h_exjon

  //       if (element.school_jon100 === 1) {
  //         // school_jon100persent += 1
  //         student_jon100persent += element.total_jon
  //         student_exjon100persent += element.total_exjon
  //         result[element[key_group]].school_jon100persent += 1
  //         result[element[key_group]].student_jon100persent += element.total_jon
  //         result[element[key_group]].student_exjon100persent += element.total_exjon

  //         if (dataTable[element.region_thai]) {
  //           dataTable[element.region_thai].school += 1
  //           dataTable[element.region_thai].student += element.total_student
  //           dataTable[element.region_thai].jon += element.total_jon
  //           dataTable[element.region_thai].exjon += element.total_exjon
  //         }

  //         if (element.sungkud) {
  //           if (element.school_size === 'ขนาดเล็ก') dataTableSungkud[element.sungkud].school_size_s += 1
  //           if (element.school_size === 'ขนาดกลาง') dataTableSungkud[element.sungkud].school_size_m += 1
  //           if (element.school_size === 'ขนาดใหญ่') dataTableSungkud[element.sungkud].school_size_l += 1
  //           if (element.school_size === 'ขนาดใหญ่พิเศษ') dataTableSungkud[element.sungkud].school_size_xl += 1
  //           dataTableSungkud[element.sungkud].school_size_sum += 1
  //         }
  //       }

  //     })
  //     setData({
  //       ...data,
  //       ...{
  //         school: school,
  //         student_exjon: student_exjon,
  //         dataTableJon100: [...Object.values(result)].sort((a, b) => (b.school_jon100persent - a.school_jon100persent)),
  //         dataTable: [...Object.values(dataTable)],
  //         dataTableSungkud: [...Object.values(dataTableSungkud)],
  //         key_group,
  //         class_k,
  //         class_p,
  //         class_m,
  //         class_h,
  //         school_jon,
  //         student_jon,
  //         school_jon100persent,
  //         student_jon100persent,
  //         student_exjon100persent,
  //       }
  //     })

  //   }

  // }, [props.data, filter])


  return (
    <div>

      <p className='text-center text-base text-sm xl:text-md font-baijam'>
        โรงเรียนที่มีนักเรียนยากจน 100 % เป็นโรงเรียนที่นักเรียนเป็นนักเรียนยากจนหรือ <br />ยากจนพิเศษทั้งโรงเรียน ซึ่งมีจำนวน <span className='text-[#038967] text-2xl'> {data.school_jon100persent.toLocaleString("en-US")}</span> โรงเรียน ทั่วทั้งหมด ดังนั้นการสำรวจ<br />ฐานข้อมูลกลุ่มโรงเรียนยากจน 100 % ในมิติต่างๆจะช่วยช่วยเหลือและสนับสนุนโรงเรียนเหล่านี้ได้ <br />
      </p>

      <div className='col-span-10 flex-col'>
        <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full'>
          < FilterKRT
            data={filter}
            onChange={(data) => {
              setFilter({ ...data })
            }}
          />
        </div>
      </div>

      <div className='grid grid-cols-10 gap-1'>

        <div className='col-span-10 lg:col-span-4 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='flex p-2  hidden xl:flex' >
              <img className="self-center w-[60px]" src={imageSchool} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>จำนวนโรงเรียนยากจน 100 %</span>
              <span className='text-2xl font-bold text-[#3b69df] text-center'>{data.school_jon100persent.toLocaleString("en-US")} โรงเรียน</span>
            </div>
          </div>
        </div>


        <div className='col-span-10 lg:col-span-3 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='flex p-2  hidden xl:flex' >
              <img className="flex self-center w-[60px] hidden xl:flex" src={students} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>นักเรียนยากจน</span>
              <span className='text-2xl font-bold text-[#048967] text-center'>{data.student_jon100persent.toLocaleString("en-US")} คน</span>
            </div>
          </div>
        </div>

        <div className='col-span-10 lg:col-span-3 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white w-full mt-2'>
            <div className='flex p-2  hidden xl:flex' >
              <img className="flex self-center w-[60px] hidden xl:flex" src={krtSudentCapital} alt="image for banner" />
            </div>
            <div className='flex flex-col p-2'>
              <span className='text-lg lx:text-xl font-bold  text-center'>ทุนเสมอภาค</span>
              <span className='text-2xl font-bold text-[#048967] text-center'>{data.student_exjon100persent.toLocaleString("en-US")} คน</span>
            </div>
          </div>
        </div>


        <div className='col-span-10 flex-col pt-2 mt-2'>

          <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl">
            <div className='text-xl font-bold text-center m-1'>โรงเรียนยากจน 100 % แบ่งตามภาค ปี{filter.year} เทอม{filter.semester}</div>
            <Table
              className='new-design-table-container'
              size="small"
              style={{ width: '100%' }}
              pagination={false}
              di
              columns={[
                {
                  title: 'ขนาด รร.',
                  dataIndex: 'key',
                  key: 'key',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "left" },
                      },
                      children: <div>{text}</div>
                    };
                  },
                  sorter: (a, b) => a.sungkud - b.sungkud,
                },
                {
                  title: 'จำนวนโรงเรียน (รร.)',
                  dataIndex: 'school',
                  key: 'school',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school - b.school,
                },
                {
                  title: 'จำนวนนักเรียน ทุนปัจจัยพื้นฐาน (คน)',
                  dataIndex: 'jon',
                  key: 'jon',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.jon - b.jon,
                },
                {
                  title: 'จำนวนนักเรียน ทุนเสมอภาค (คน)',
                  dataIndex: 'exjon',
                  key: 'exjon',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.exjon - b.exjon,
                }
              ]}
              dataSource={data.dataTable}
            >

            </Table>

          </div>
        </div>


        <div className='col-span-10 flex-col pt-2 mt-2'>
          <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl">
            <div className='text-xl font-bold text-center m-1'>ขนาดโรงเรียนยากจน 100% แบ่งตามสังกัด ปี{filter.year} เทอม{filter.semester}</div>
            <Table
              size="small"
              bordered
              style={{ width: '100%' }}
              scroll={{
                x: '100%',
                y: 300,
              }}
              pagination={false}
              columns={[
                {
                  title: 'สังกัด รร.',
                  dataIndex: 'key',
                  key: 'key',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "left" }
                      },
                      children: <div>{text}</div>
                    };
                  },
                  sorter: (a, b) => a.sungkud - b.sungkud,
                },
                {
                  title: 'เล็ก',
                  dataIndex: 'school_size_s',
                  key: 'school_size_s',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_size_s - b.school_size_s,
                },
                {
                  title: 'กลาง',
                  dataIndex: 'school_size_m',
                  key: 'school_size_m',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_size_m - b.school_size_m,
                },
                {
                  title: 'ใหญ่',
                  dataIndex: 'school_size_l',
                  key: 'school_size_l',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_size_l - b.school_size_l,
                },
                {
                  title: 'ใหญ่พิเศษ',
                  dataIndex: 'school_size_xl',
                  key: 'school_size_xl',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_size_xl - b.school_size_xl,
                },
                {
                  title: 'รวม',
                  dataIndex: 'school_size_sum',
                  key: 'school_size_sum',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        style: { textAlign: "right" }
                      },
                      children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_size_sum - b.school_size_sum,
                }
              ]}
              dataSource={data.dataTableSungkud}
            >

            </Table>
          </div>
        </div>


        <div className='col-span-10 flex-col pt-2 mt-2'>
          <div className="w-full bg-[#FFFFFF] border-[1px] border-solid p-[10px] mx-auto rounded-xl shadow-xl">
            <div className='text-xl font-bold text-center m-1'>โรงเรียนยากจน 100% แบ่งตามจังหวัด ปี{filter.year} เทอม{filter.semester}</div>
            <Table
              size="small"
              bordered
              style={{ width: '100%' }}
              scroll={{
                x: '100%',
                y: 400,
              }}
              columns={[
                {
                  title: 'จังหวัด',
                  dataIndex: 'key',
                  key: 'key',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "left" }
                      },
                      children: <div>{text}</div>
                    };
                  },
                  sorter: (a, b) => a.key - b.key,
                },
                {
                  title: 'จำนวนโรงเรียนยากจน 100%',
                  dataIndex: 'school_jon100persent',
                  key: 'school_jon100persent',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text}</div>
                      // children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.school_jon100persent - b.school_jon100persent,
                },
                {
                  title: 'จำนวนนักเรียนทุนปัจจัยพื้นฐาน',
                  dataIndex: 'student_jon100persent',
                  key: 'student_jon100persent',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text}</div>
                      // children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student_jon100persent - b.student_jon100persent,
                },
                {
                  title: 'จำนวนนักเรียนทุนเสมอภาค',
                  dataIndex: 'student_exjon100persent',
                  key: 'student_exjon100persent',
                  align: 'center',
                  render(text, record) {
                    return {
                      props: {
                        // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                        style: { textAlign: "right" }
                      },
                      children: <div>{text}</div>
                      // children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    };
                  },
                  sorter: (a, b) => a.student_exjon100persent - b.student_exjon100persent,
                }
              ]}
              dataSource={data.dataTableJon100.filter(item => item.school_jon100persent !== 0)}
            >

            </Table>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SungkudView
