import React, { useState, useEffect } from 'react';
import imageSchool from '/images/cct-3.png';
import krtSudentCapital from '/images/cct-2.png';
import students_black from '/images/student-black.png';
import students_red from '/images/student-red.png';
import students_blue from '/images/student-blue.png';
import FilterKRT from './FilterKRT'

const Overview = (props) => {
  let { data, filter, setFilter } = props;
  // const [data, setData] = useState({
  //   school_total: 0,
  //   student_exjon: 0,
  //   student_total: 0,
  //   dataTable: [],
  //   dataTableSungkud: [],
  //   dataTableJon100: [],
  //   name: '',
  //   key_group: 'province_name',
  //   class_k: 0,
  //   class_p: 0,
  //   class_m: 0,
  //   class_h: 0,
  //   student_jon100persent: 0,
  //   school_jon100persent: 0,
  //   school_jon: 0,
  //   student_jon: 0,
  //   countAllTunStudent: props.data?.countAllTunStudent ? props.data.countAllTunStudent : 0,
  //   countAllTunSchool: props.data?.countAllTunSchool ? props.data.countAllTunSchool : 0,
  //   student_exjon_25612: props.data.student_exjon_25612,
  //   student_exjon_25621: props.data.student_exjon_25621,
  //   student_exjon_25622: props.data.student_exjon_25622,
  //   student_exjon_25631: props.data.student_exjon_25631,
  //   student_exjon_25632: props.data.student_exjon_25632,
  //   student_exjon_25641: props.data.student_exjon_25641,
  //   student_exjon_25642: props.data.student_exjon_25642,
  //   student_exjon_25651: props.data.student_exjon_25651,
  //   student_max_exjon: Math.max(
  //     props.data.student_exjon_25612,
  //     props.data.student_exjon_25621,
  //     props.data.student_exjon_25622,
  //     props.data.student_exjon_25631,
  //     props.data.student_exjon_25632,
  //     props.data.student_exjon_25641,
  //     props.data.student_exjon_25642,
  //     props.data.student_exjon_25651,
  //   ),
  //   sum_student: (
  //     props.data.student_exjon_25612 +
  //     props.data.student_exjon_25621 +
  //     props.data.student_exjon_25622 +
  //     props.data.student_exjon_25631 +
  //     props.data.student_exjon_25632 +
  //     props.data.student_exjon_25641 +
  //     props.data.student_exjon_25642 +
  //     props.data.student_exjon_25651
  //   )
  // });

  // useEffect(() => {
  //   let student_exjon = 0
  //   let school_total = 0
  //   let student_total = 0
  //   let key_group = 'province_name'
  //   let result = {}
  //   let name = ''
  //   let class_k = 0
  //   let class_p = 0
  //   let class_m = 0
  //   let class_h = 0
  //   let school_jon = 0
  //   let student_jon = 0
  //   let student_jon100persent = 0
  //   let school_jon100persent = 0
  //   let exjon = 0
  //   let dataTable = {
  //     'ขนาดเล็ก': { key: 'เล็ก', school: 0, student: 0, exjon: 0 },
  //     'ขนาดกลาง': { key: 'กลาง', school: 0, student: 0, exjon: 0 },
  //     'ขนาดใหญ่': { key: 'ใหญ่', school: 0, student: 0, exjon: 0 },
  //     'ขนาดใหญ่พิเศษ': { key: 'ใหญ่พิเศษ', school: 0, student: 0, exjon: 0 }
  //   }

  //   let dataTableSungkud = {
  //     'สพฐ.': { key: 'สพฐ.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'อปท.': { key: 'อปท.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'ตชด.': { key: 'ตชด.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'สช.': { key: 'สช.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //     'พศ.': { key: 'พศ.', school_size_s: 0, school_size_m: 0, school_size_l: 0, school_size_xl: 0, school_size_sum: 0 },
  //   }

  //   let years = ['25612', '25621', '25622', '25631', '25632', '25641', '25642', '25651']
  //   let objEx = {
  //     student_exjon_25612: 0,
  //     student_exjon_25621: 0,
  //     student_exjon_25622: 0,
  //     student_exjon_25631: 0,
  //     student_exjon_25632: 0,
  //     student_exjon_25641: 0,
  //     student_exjon_25642: 0,
  //     student_exjon_25651: 0,
  //   }


  //   years.forEach(_year => {
  //     if (props.data[`data${_year}`]) {
  //       let _data = props.data[`data${_year}`]

  //       if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

  //       if (filter.view === 'country') {
  //         key_group = 'province_name'
  //         _data = _data.map(x => ({ ...x, type: 'province_name' }))
  //       }

  //       if (filter.view === 'province') {
  //         key_group = 'city_name'
  //         name = 'จังหวัด' + filter.province_name
  //         _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
  //       }


  //       if (filter.view === 'city') {
  //         key_group = 'tumbon_name'
  //         name = 'อำเภอ' + filter.city_name
  //         _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
  //           .map(x => ({ ...x, type: 'tumbon_name' }))
  //       }
  //       _data.forEach(element => {
  //         objEx[`student_exjon_${_year}`] += element.total_exjon
  //       })
  //     }
  //   })


  //   if (props.data[`data${filter.year}${filter.semester}`]) {
  //     let _data = props.data[`data${filter.year}${filter.semester}`]
  //     let sizeschool = _data.length

  //     if (filter.sungkud !== 'ทั้งหมด') _data = _data.filter(item => item.sungkud === filter.sungkud)

  //     if (filter.view === 'country') {
  //       key_group = 'province_name'
  //       _data = _data.map(x => ({ ...x, type: 'province_name' }))
  //     }

  //     if (filter.view === 'province') {
  //       key_group = 'city_name'
  //       name = 'จังหวัด' + filter.province_name
  //       _data = _data.filter(x => x.province_name === filter.province_name).map(x => ({ ...x, type: 'city_name' }))
  //     }


  //     if (filter.view === 'city') {
  //       key_group = 'tumbon_name'
  //       name = 'อำเภอ' + filter.city_name
  //       _data = _data.filter(x => x.province_name === filter.province_name && x.city_name === filter.city_name)
  //         .map(x => ({ ...x, type: 'tumbon_name' }))
  //     }

  //     _data.forEach(element => {
  //       student_exjon += element.total_exjon
  //       if (!result[element[key_group]]) {
  //         result[element[key_group]] = {
  //           key: element[key_group],
  //           school: 0,
  //           student: 0,
  //           student_jon: 0,
  //           student_exjon: 0,
  //           sum_jon_exjon: 0,

  //           class_k: 0,
  //           class_p: 0,
  //           class_m: 0,
  //           class_h: 0,
  //           school_jon100: 0,
  //           student_jon100persent: 0,
  //           school_jon100persent: 0,
  //         }
  //       }
  //       result[element[key_group]].school += 1
  //       result[element[key_group]].student += element[`total_student`]
  //       result[element[key_group]].student_jon += element[`total_jon`]
  //       result[element[key_group]].student_exjon += element[`total_exjon`]
  //       result[element[key_group]].sum_jon_exjon += (element[`total_jon`] + element[`total_exjon`])

  //       exjon += element[`total_exjon`]

  //       student_total += element.total_student
  //       school_total += 1
  //       if (element.total_exjon > 0) {
  //         school_jon += 1
  //       }
  //       if (element.k_exjon > 0) class_k += element.k_exjon
  //       if (element.p_exjon > 0) class_p += element.p_exjon
  //       if (element.m_exjon > 0) class_m += element.m_exjon
  //       if (element.h_exjon > 0) class_h += element.h_exjon
  //       if (dataTable[element.school_size]) {
  //         dataTable[element.school_size].school += 1
  //         dataTable[element.school_size].student += element.total_student
  //         dataTable[element.school_size].exjon += element.total_exjon
  //       }
  //       if (element.school_jon100 === 1) {
  //         school_jon100persent += 1
  //         student_jon100persent += element.total_exjon
  //         result[element[key_group]].school_jon100persent += 1
  //         result[element[key_group]].student_jon100persent += element.total_exjon
  //       }

  //       if (element.sungkud) {
  //         if (element.school_size === 'ขนาดเล็ก') dataTableSungkud[element.sungkud].school_size_s += 1
  //         if (element.school_size === 'ขนาดกลาง') dataTableSungkud[element.sungkud].school_size_m += 1
  //         if (element.school_size === 'ขนาดใหญ่') dataTableSungkud[element.sungkud].school_size_l += 1
  //         if (element.school_size === 'ขนาดใหญ่พิเศษ') dataTableSungkud[element.sungkud].school_size_xl += 1
  //         dataTableSungkud[element.sungkud].school_size_sum += 1
  //       }

  //     })
  //     setData({
  //       ...data,
  //       ...{
  //         school_total: school_total,
  //         // sizeschool,
  //         // school_total,
  //         student_total,
  //         school_jon,
  //         student_exjon: student_exjon,
  //         dataTableJon100: [...Object.values(result)],
  //         dataTable: [...Object.values(dataTable)],
  //         dataTableSungkud: [...Object.values(dataTableSungkud)],
  //         key_group,
  //         class_k,
  //         class_p,
  //         class_m,
  //         class_h,
  //         student_jon,
  //         school_jon100persent,
  //         student_jon100persent,
  //         student_exjon_25612: objEx.student_exjon_25612,
  //         student_exjon_25621: objEx.student_exjon_25621,
  //         student_exjon_25622: objEx.student_exjon_25622,
  //         student_exjon_25631: objEx.student_exjon_25631,
  //         student_exjon_25632: objEx.student_exjon_25632,
  //         student_exjon_25641: objEx.student_exjon_25641,
  //         student_exjon_25642: objEx.student_exjon_25642,
  //         student_exjon_25651: objEx.student_exjon_25651,
  //         student_max_exjon: Math.max(
  //           objEx.student_exjon_25612,
  //           objEx.student_exjon_25621,
  //           objEx.student_exjon_25622,
  //           objEx.student_exjon_25631,
  //           objEx.student_exjon_25632,
  //           objEx.student_exjon_25641,
  //           objEx.student_exjon_25642,
  //           objEx.student_exjon_25651,
  //         ),
  //       }
  //     })

  //   }

  // }, [props.data, filter])


  console.log("data [Overview]: ", data)

  return (
    <div className='flex flex-col'>
      <p className='text-center text-base xl:text-md font-baijam'>
        โครงการทุนเสมอภาคได้ช่วยเหลือนักเรียนในภาคเรียนที่ {filter.year} ภาคเรียนที่ {filter.semester}<br />
        โดยมีกลุ่มเป้าหมายเป็นเด็กนักเรียนในระดับการศึกษาผู้ขาดแคลนทุนทรัพย์ โดยมีนักเรียน<br />
        ได้รับทุนเสมอภาคจำนวน <span className='text-[#038967] text-2xl'> {data.student_exjon.toLocaleString("en-US")}</span> คน
        ครอบคลุมโรงเรียน <span className='text-[#038967] text-2xl'> {data.school_jon.toLocaleString("en-US")}</span> โรงเรียน 
      </p>
      <div className='col-span-10 flex-col'>
        <div
          className='relativef flex flex-row justify-center w-full rounded-lg shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'
        >
          < FilterKRT
            data={filter}
            onChange={(data) => {
              setFilter({ ...data })
            }}
          />
        </div>
      </div>

      <div className='grid grid-cols-9 xl:grid-cols-10 gap-x-2 gap-y-2 mt-4 w-full'>

        <div className='col-span-9 md:col-span-3 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-min md:h-[320px]'>
            <div className='flex flex-col p-4  justify-evenly h-full'>
              <span className='font-bold text-center text-lg xl:text-xl'>จำนวนโรงเรียน<br />ที่ช่วยเหลือ</span>
              <img className="hidden md:flex self-center w-[100px] lg:w-[120px]" src={imageSchool} alt="images" />
              <div className='flex flex-col'>
                <span className='text-2xl xl:text-2xl font-bold text-[#3b69df] text-center'>{data.school_jon.toLocaleString("en-US")}</span>
                <span className='text-xl font-bold text-center'>โรงเรียน</span>
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-9 md:col-span-3 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-min md:h-[320px]'>
            <div className='flex flex-col p-4  justify-evenly h-full'>
              <span className='font-bold text-center text-lg xl:text-xl'>จำนวนนักเรียน<br />ได้รับทุนเสมอภาค</span>
              <img className="hidden md:flex  self-center w-[100px] lg:w-[120px]" src={krtSudentCapital} alt="images" />
              <div className='flex flex-col'>
                <span className='text-2xl xl:text-2xl font-bold text-[#3b69df] text-center'>{data.student_exjon.toLocaleString("en-US")}</span>
                <span className='text-xl font-bold text-center'>คน</span>
              </div>
            </div>
          </div>
        </div>


        <div className='col-span-9 md:col-span-3 xl:col-span-4 flex-col'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-[80px]'>
            <div className='w-[60px] flex-none items-center justify-center flex md:hidden xl:flex'>
              <img className="flex self-center m-0 w-[48px]" src={students_red} alt="images" />
            </div>
            <div className='flex flex-col p-2 flex-1'>
              <span className='text-md lg:text-lg font-bold  text-center'>อนุบาล</span>
              <span className='text-xl font-bold text-[#0A9A55] text-center'>{data.class_k.toLocaleString("en-US")} คน</span>
            </div>
          </div>

          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-[80px]'>
            <div className='w-[60px] flex-none items-center justify-center flex md:hidden xl:flex'>
              <img className="flex self-center m-0 w-[48px]" src={students_blue} alt="images" />
            </div>
            <div className='flex flex-col p-2 flex-1'>
              <span className='text-md lg:text-lg font-bold  text-center'>ประถมศึกษา</span>
              <span className='text-xl font-bold text-[#0A9A55] text-center'>{data.class_p.toLocaleString("en-US")} คน</span>
            </div>
          </div>

          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-[80px]'>
            <div className='w-[60px] flex-none items-center justify-center flex md:hidden xl:flex'>
              <img className="flex self-center m-0 w-[48px]" src={students_black} alt="images" />
            </div>
            <div className='flex flex-col p-2 flex-1'>
              <span className='text-sm lg:text-md 2xl:text-xl font-bold text-center'>มัธยมศึกษาตอนต้น</span>
              <span className='text-xl font-bold text-[#0A9A55] text-center'>{data.class_m.toLocaleString("en-US")} คน</span>
            </div>
          </div>

          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white h-[80px]'>
            <div className='w-[60px] flex-none items-center justify-center flex md:hidden xl:flex'>
              <img className="flex self-center m-0 w-[48px]" src={students_black} alt="images" />
            </div>
            <div className='flex flex-col p-2 flex-1'>
              <span className='text-sm lg:text-md 2xl:text-xl font-bold text-center'>มัธยมศึกษาตอนปลาย</span>
              <span className='text-xl font-bold text-[#0A9A55] text-center'>{data.class_h.toLocaleString("en-US")} คน</span>
            </div>
          </div>

        </div>
      </div>

      <div className='grid grid-cols-10 mt-4 gap-y-2 w-full'>

        <div className='col-span-10 md:col-span-5 flex-col ml-1'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>

            <div className='flex flex-col p-4 w-full'>
              <span className='text-lg xl:text-xl font-bold'>จำนวนโรงเรียนที่ช่วยเหลือ</span>
              <span className='text-3xl xl:text-5xl font-bold text-[#3B69DF]'>{data.school_jon.toLocaleString("en-US")} <span className='text-base text-gray-400'> โรงเรียน</span></span>
              <div className="w-full bg-gray-200 rounded-full h-2.5 my-4">
                <div className="bg-[#3B69DF] h-2.5 rounded-full" style={{ width: `${(data.school_jon / data.school_total) * 100}%` }}></div>
              </div>
              <div className='flex flex-row w-full'>
                <div className='flex flex-1 flex-col'>
                  <span className='text-md xl:text-lg font-bold'>จากโรงเรียนทั้งหมด</span>
                  <span className='text-xl xl:text-3xl font-bold text-[#3B69DF]'>{data.school_total.toLocaleString("en-US")}  <span className='text-base text-gray-400'> โรงเรียน</span></span>
                </div>
                <div className='flex flex-col w-[75px] xl:w-[100px]'>
                  <div className='w-full h-full rounded-lg flex flex-col shadow-xl text-center bg-[#E2E8FA]'>
                    <span className='text-sm xl:text-md m-auto text-left'>คิดเป็น</span>
                    <span className='text-xl xl:text-3xl m-auto font-bold text-[#3B69DF]'>{((data.school_jon / data.school_total) * 100).toFixed(2)}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='col-span-10 md:col-span-5 flex-col ml-1'>
          <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>

            <div className='flex flex-col p-4 w-full'>
              <span className='text-lg xl:text-xl font-bold'>จำนวนนักเรียนที่ได้รับทุนเสมอภาค</span>
              <span className='text-3xl xl:text-5xl font-bold text-[#0A9A55]'>{data.student_exjon.toLocaleString("en-US")} <span className='text-base text-gray-400'> คน</span></span>
              <div className="w-full bg-gray-200 rounded-full h-2.5 my-4">
                <div className="bg-[#0A9A55] h-2.5 rounded-full" style={{ width: `${(data.student_exjon / data.student_total) * 100}%` }}></div>
              </div>
              <div className='flex flex-row w-full'>
                <div className='flex flex-1 flex-col'>
                  <span className='text-md xl:text-lg font-bold'>จากนักเรียนทั้งหมด</span>
                  <span className='text-xl xl:text-3xl font-bold text-[#0A9A55]'>{data.student_total.toLocaleString("en-US")}  <span className='text-base text-gray-400'> คน</span></span>
                </div>
                <div className='flex flex-col w-[75px] xl:w-[100px]'>
                  <div className='w-full h-full rounded-lg flex flex-col shadow-xl text-center bg-[#DAF0E6]'>
                    <span className='text-sm xl:text-md m-auto text-left'>คิดเป็น</span>
                    <span className='text-xl xl:text-3xl m-auto font-bold text-[#0A9A55]'>{((data.student_exjon / data.student_total) * 100).toFixed(2)}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
}

export default Overview
